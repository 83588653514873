import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "8b7f15f4-32b8-4796-9cce-b515537c9386"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards8b7f15f432b847969cceB515537c9386(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards8b7f15f432b847969cceB515537c9386", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "4f725fd3-2a64-4068-81b7-98f34c4d05de"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title4f725fd32a64406881b798f34c4d05de = "Leveling";

// id: "55269096-9e3f-487d-b01b-49ad4f701d96"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages552690969e3f487dB01b49ad4f701d96(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages552690969e3f487dB01b49ad4f701d96`}>
      {props.children}
    </div>
  );
}

// id: "4dfbaa80-4310-4cef-8334-1016490dcbd2"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader4dfbaa8043104cef83341016490dcbd2(props: any) {
  return (
    <ShortHeaderD57cb82bE91f435e84233c6455df47d8 parentTag="DefaultHeader4dfbaa8043104cef83341016490dcbd2" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "eee0c835-5dd3-4fe7-8a0d-182ff7147c6c"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c(props: any) {
  return (
    <ShortHeaderD57cb82bE91f435e84233c6455df47d8 parentTag="MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c" header-top={<DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b parentTag="HeaderTopDe7f07ddD53442f885518ca932c5c5f8" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "3efb211c-7e0f-4c05-baf6-f4c8b745d8da"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody3efb211c7e0f4c05Baf6F4c8b745d8da(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody3efb211c7e0f4c05Baf6F4c8b745d8da page-body`}>
      {props["marketingCards"] || <MarketingCards8b7f15f432b847969cceB515537c9386 parentTag="MarketingCards07cd5a036ac4441887e48a7f469f8dc1" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "7970c532-ac5b-4fea-96b0-bef37ca962fd"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage7970c532Ac5b4fea96b0Bef37ca962fd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage7970c532Ac5b4fea96b0Bef37ca962fd page`}>
      {props["header"] || <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header0cd13f50Def548c892d7C1c919ef3fc1" title="About Us" {...props} />}
      {props["body"] || <MarketingBody3efb211c7e0f4c05Baf6F4c8b745d8da parentTag="Body6b02f85fD6fd416cA3f0Bccf5e546cd7" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="FooterEa327cd6F9504e9a9ba9Cfcd43975931" {...props} />}
    </div>
  );
}

// id: "107df6af-788e-450e-b6f6-355309bdb217"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage107df6af788e450eB6f6355309bdb217(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage107df6af788e450eB6f6355309bdb217`}>
      {props["header"] || <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header84f8dd58B98c413dA42a2ab714389c6b" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="Footer1f04d66c4aa54457A4b92db32a364122" {...props} />}
    </div>
  );
}

// id: "377555a9-67ee-4016-9012-cc0cdc7b18f2"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages377555a967ee40169012Cc0cdc7b18f2(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages377555a967ee40169012Cc0cdc7b18f2 gf-page-layout`}>
      {props["header"] || <MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c parentTag="HeaderFac9f4a08e774048Bda04105f3eeba98" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavB1ad2911256a4c84B99f957e201f9848", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="Footer65b9f93a48fb42ea879328caaca094a5" {...props} />}
    </div>
  );
}

// id: "b4bbfb01-b716-404a-92bd-ead16728f20f"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultB4bbfb01B716404a92bdEad16728f20f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultB4bbfb01B716404a92bdEad16728f20f page`}>
      {props["header"] || <MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c parentTag="Header1430fcb0568542569d6a7891c4d52b09" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="FooterF075f216Aa5e43f8A05804e8f37f4186" {...props} />}
    </div>
  );
}

// id: "da7c4aea-db4e-46ca-8b43-e72d983e8786"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageDa7c4aeaDb4e46ca8b43E72d983e8786(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageDa7c4aeaDb4e46ca8b43E72d983e8786`}>
      {props["header"] || <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="HeaderC61fc4eeA5f74dd389472070dc1ac8af" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="FooterB6cddcd679304ab392bd7c64b0d2770e" {...props} />}
    </div>
  );
}

// id: "92e44c81-67cc-4039-a5bc-1e66a0f2be9f"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage92e44c8167cc4039A5bc1e66a0f2be9f(props: any) {
  return (
    <SecondaryPageDa7c4aeaDb4e46ca8b43E72d983e8786 parentTag="ResourcesPage92e44c8167cc4039A5bc1e66a0f2be9f" header={<SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header767a2d641b1d46d1A567Ba70828cc5d3" title="Resources" />} {...props} />
  );
}

// id: "adae0295-a466-4693-bff3-4a04dd43b732"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelAdae0295A4664693Bff34a04dd43b732(props: any) {
  return (
    <DefaultLogoB775c3f720f749fdA09d26419e641cf3 parentTag="LabelAdae0295A4664693Bff34a04dd43b732" {...props} />
  );
}

// id: "d57cb82b-e91f-435e-8423-3c6455df47d8"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderD57cb82bE91f435e84233c6455df47d8(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderD57cb82bE91f435e84233c6455df47d8 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo5ea963f9755b4186Ad38F83a2790890c", parentTag)} label={<DefaultLogoB775c3f720f749fdA09d26419e641cf3 parentTag="LabelFb8fe0577e55429eAfffAf20a576a33a" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation7e66b6cc8ebb4149Afe18e72509888e1", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "b775c3f7-20f7-49fd-a09d-26419e641cf3"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoB775c3f720f749fdA09d26419e641cf3(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoB775c3f720f749fdA09d26419e641cf3 logo`} alt="logo" />
  );
}

// id: "fc164688-dcbc-4606-803b-085e7575d74c"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteFc164688Dcbc4606803b085e7575d74c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteFc164688Dcbc4606803b085e7575d74c`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "d042eabb-49fb-4abc-ba34-564de8e63056"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageD042eabb49fb4abcBa34564de8e63056(props: any) {
  return (
    <SecondaryPageDa7c4aeaDb4e46ca8b43E72d983e8786 parentTag="JoinUsPageD042eabb49fb4abcBa34564de8e63056" header={<SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header3e992504Ac194fffAcb203a6ae7120e4" title="Join Us" />} {...props} />
  );
}

// id: "17195ad0-eb36-487c-8c44-56d7269b8d61"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href17195ad0Eb36487c8c4456d7269b8d61 = "/";

// id: "d23f2e9a-b246-44dc-ad3a-0bd22ffc8ea8"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterD23f2e9aB24644dcAd3a0bd22ffc8ea8(props: any) {
  return (
    <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="FooterD23f2e9aB24644dcAd3a0bd22ffc8ea8" {...props} />
  );
}

// id: "4ab8c340-9caf-49df-bb2c-7265428fcfe3"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages4ab8c3409caf49dfBb2c7265428fcfe3(props: any) {
  return (
    <SecondaryPageDa7c4aeaDb4e46ca8b43E72d983e8786 parentTag="SignupPages4ab8c3409caf49dfBb2c7265428fcfe3" header={<SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header0298f0db2a3b44e6Ba8580c98c13a042" title="Join Us" />} {...props} />
  );
}

// id: "2edaff9a-9aed-47aa-b9ba-7b20c129f8ef"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero2edaff9a9aed47aaB9ba7b20c129f8ef(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero2edaff9a9aed47aaB9ba7b20c129f8ef hero`}>
      <div className="title">
        {props["title"] || Title94e54ebb15ba499d94f22b081ef6ee0e}
      </div>
    </div>
  );
}

// id: "5e0b88fc-ba13-4013-addf-f96b9db0dcbe"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header5e0b88fcBa134013AddfF96b9db0dcbe(props: any) {
  return (
    <MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c parentTag="Header5e0b88fcBa134013AddfF96b9db0dcbe" {...props} />
  );
}

// id: "c0ed84f3-2c3d-42a3-ab25-9d46d24014a0"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links849e2304881f49d8A0efF0c5c96ac99c", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoD4f3fbe1C16f41dd880a97d1480f5c4c />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("CopyrightDda274b0316b4f37B684490f323eaaa9", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowC795b17d3a4f4aceAc3423a0b83ebea2", parentTag)} {...props} />}
    </div>
  );
}

// id: "ad2fd0f0-ef24-4340-bc43-751a28d7648b"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoBbc857f773ee4997B12eC6e06cb8d884", parentTag)} url="/" className="linked-logo" label={<DefaultLogoB775c3f720f749fdA09d26419e641cf3 parentTag="Label4f4d4e2fDffc4df7B65c31a532ee0492" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationE6453af86e3645eeAf12A981e97a2f3b", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "deb2d979-2771-4849-8fb0-2c7e2e0569ea"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageDeb2d979277148498fb02c7e2e0569ea(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageDeb2d979277148498fb02c7e2e0569ea`}>
      {props["header"] || <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header2181c56bB8eb4da383df3ee38f4d127e" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="Footer4bf0be39B8a74f98B5157cecc4cc9c29" {...props} />}
    </div>
  );
}

// id: "89518373-bc40-4eff-af6d-2d4718461a7d"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages89518373Bc404effAf6d2d4718461a7d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages89518373Bc404effAf6d2d4718461a7d`}>
      {props["header"] || <MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c parentTag="Header5e8b964d34364f4dB208Eb634d0a86f3" {...props} />}
      {props.children}
    </div>
  );
}

// id: "e59e5986-508e-4202-a74a-97f145e038fc"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroE59e5986508e4202A74a97f145e038fc(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroE59e5986508e4202A74a97f145e038fc hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button7fdb7bea54ce4414Adc364552333b339", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "4815606b-f63e-4702-8896-62262873f889"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader4815606bF63e4702889662262873f889(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader4815606bF63e4702889662262873f889 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b parentTag="HeaderTopA024439cDc10426fBcb670b6d840220d" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero2edaff9a9aed47aaB9ba7b20c129f8ef parentTag="Hero58d7e8d60a95435eAf2aF8520b0f9aab" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "aae363fd-fd2f-466f-ae8f-31e06d6eff6e"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageAae363fdFd2f466fAe8f31e06d6eff6e(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageAae363fdFd2f466fAe8f31e06d6eff6e page`}>
      {props["header"] || <HomeHeader4ef4c2daDd4146d2A0f0F5bd72161148 parentTag="Header24841a2074594180B2489a61daf1a042" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack56c1117cBdc64c36B970Dec6edbf88d3", parentTag)} {...props} />}
      {props["body"] || <MarketingBody3efb211c7e0f4c05Baf6F4c8b745d8da parentTag="Body3c2dc225Beaa43ca9d6109ef49ccf6da" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote5fcdfe5d996c4b50Ab5478d5c3995b1a", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="Footer1b54ae1104df4e898676A0b399e67cc5" {...props} />}
    </div>
  );
}

// id: "4ef4c2da-dd41-46d2-a0f0-f5bd72161148"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader4ef4c2daDd4146d2A0f0F5bd72161148(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader4ef4c2daDd4146d2A0f0F5bd72161148 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b parentTag="HeaderTopEbbe9be14aa44bf7A49aF6e223ec9fa1" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroE59e5986508e4202A74a97f145e038fc parentTag="HeroE3385039E26944feB373373ceaeebd75" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

// id: "849e2304-881f-49d8-a0ef-f0c5c96ac99c"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "c0ed84f3-2c3d-42a3-ab25-9d46d24014a0"
export function Links849e2304881f49d8A0efF0c5c96ac99c(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links849e2304881f49d8A0efF0c5c96ac99c", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "58d7e8d6-0a95-435e-af2a-f8520b0f9aab"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "4815606b-f63e-4702-8896-62262873f889"
export function Hero58d7e8d60a95435eAf2aF8520b0f9aab(props: any) {
  return (
    <SecondaryMarketingHero2edaff9a9aed47aaB9ba7b20c129f8ef parentTag="Hero58d7e8d60a95435eAf2aF8520b0f9aab" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "65b9f93a-48fb-42ea-8793-28caaca094a5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "377555a9-67ee-4016-9012-cc0cdc7b18f2"
export function Footer65b9f93a48fb42ea879328caaca094a5(props: any) {
  return (
    <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="Footer65b9f93a48fb42ea879328caaca094a5" {...props} />
  );
}

// id: "1430fcb0-5685-4256-9d6a-7891c4d52b09"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b4bbfb01-b716-404a-92bd-ead16728f20f"
export function Header1430fcb0568542569d6a7891c4d52b09(props: any) {
  return (
    <MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c parentTag="Header1430fcb0568542569d6a7891c4d52b09" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "fac9f4a0-8e77-4048-bda0-4105f3eeba98"
// title: ""
// type: :reference
// key: "header"
// parent_id: "377555a9-67ee-4016-9012-cc0cdc7b18f2"
export function HeaderFac9f4a08e774048Bda04105f3eeba98(props: any) {
  return (
    <MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c parentTag="HeaderFac9f4a08e774048Bda04105f3eeba98" {...props} />
  );
}

// id: "24841a20-7459-4180-b248-9a61daf1a042"
// title: ""
// type: :reference
// key: "header"
// parent_id: "aae363fd-fd2f-466f-ae8f-31e06d6eff6e"
export function Header24841a2074594180B2489a61daf1a042(props: any) {
  return (
    <HomeHeader4ef4c2daDd4146d2A0f0F5bd72161148 parentTag="Header24841a2074594180B2489a61daf1a042" {...props} />
  );
}

// id: "56c1117c-bdc6-4c36-b970-dec6edbf88d3"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "aae363fd-fd2f-466f-ae8f-31e06d6eff6e"
export function WelcomeBack56c1117cBdc64c36B970Dec6edbf88d3(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack56c1117cBdc64c36B970Dec6edbf88d3", parentTag)} {...props} />
  );
}

// id: "ea327cd6-f950-4e9a-9ba9-cfcd43975931"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "7970c532-ac5b-4fea-96b0-bef37ca962fd"
export function FooterEa327cd6F9504e9a9ba9Cfcd43975931(props: any) {
  return (
    <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="FooterEa327cd6F9504e9a9ba9Cfcd43975931" {...props} />
  );
}

// id: "f075f216-aa5e-43f8-a058-04e8f37f4186"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b4bbfb01-b716-404a-92bd-ead16728f20f"
export function FooterF075f216Aa5e43f8A05804e8f37f4186(props: any) {
  return (
    <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="FooterF075f216Aa5e43f8A05804e8f37f4186" {...props} />
  );
}

// id: "1b54ae11-04df-4e89-8676-a0b399e67cc5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "aae363fd-fd2f-466f-ae8f-31e06d6eff6e"
export function Footer1b54ae1104df4e898676A0b399e67cc5(props: any) {
  return (
    <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="Footer1b54ae1104df4e898676A0b399e67cc5" {...props} />
  );
}

// id: "c61fc4ee-a5f7-4dd3-8947-2070dc1ac8af"
// title: ""
// type: :reference
// key: "header"
// parent_id: "da7c4aea-db4e-46ca-8b43-e72d983e8786"
export function HeaderC61fc4eeA5f74dd389472070dc1ac8af(props: any) {
  return (
    <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="HeaderC61fc4eeA5f74dd389472070dc1ac8af" title="Secondary Page" {...props} />
  );
}

// id: "cbdf4c42-4d58-4888-9507-32f880482563"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "8b7f15f4-32b8-4796-9cce-b515537c9386"
export const PostSlugCbdf4c424d584888950732f880482563 = "home-page-posts2";

// id: "5e8b964d-3436-4f4d-b208-eb634d0a86f3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "89518373-bc40-4eff-af6d-2d4718461a7d"
export function Header5e8b964d34364f4dB208Eb634d0a86f3(props: any) {
  return (
    <MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c parentTag="Header5e8b964d34364f4dB208Eb634d0a86f3" {...props} />
  );
}

// id: "bbc857f7-73ee-4997-b12e-c6e06cb8d884"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "ad2fd0f0-ef24-4340-bc43-751a28d7648b"
export function LinkedLogoBbc857f773ee4997B12eC6e06cb8d884(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoBbc857f773ee4997B12eC6e06cb8d884", parentTag)} url="/" className="linked-logo" label={<DefaultLogoB775c3f720f749fdA09d26419e641cf3 parentTag="Label4f4d4e2fDffc4df7B65c31a532ee0492" alt="logo" />} {...props} />
  );
}

// id: "3c2dc225-beaa-43ca-9d61-09ef49ccf6da"
// title: ""
// type: :reference
// key: "body"
// parent_id: "aae363fd-fd2f-466f-ae8f-31e06d6eff6e"
export function Body3c2dc225Beaa43ca9d6109ef49ccf6da(props: any) {
  return (
    <MarketingBody3efb211c7e0f4c05Baf6F4c8b745d8da parentTag="Body3c2dc225Beaa43ca9d6109ef49ccf6da" {...props} />
  );
}

// id: "2181c56b-b8eb-4da3-83df-3ee38f4d127e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "deb2d979-2771-4849-8fb0-2c7e2e0569ea"
export function Header2181c56bB8eb4da383df3ee38f4d127e(props: any) {
  return (
    <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header2181c56bB8eb4da383df3ee38f4d127e" title="Leveling" {...props} />
  );
}

// id: "e3385039-e269-44fe-b373-373ceaeebd75"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "4ef4c2da-dd41-46d2-a0f0-f5bd72161148"
export function HeroE3385039E26944feB373373ceaeebd75(props: any) {
  return (
    <HomePageHeroE59e5986508e4202A74a97f145e038fc parentTag="HeroE3385039E26944feB373373ceaeebd75" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "e7a6b890-94eb-44a1-9926-04ca79e36fde"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "8b7f15f4-32b8-4796-9cce-b515537c9386"
export const ButtonClassE7a6b89094eb44a1992604ca79e36fde = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "b1ad2911-256a-4c84-b99f-957e201f9848"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "377555a9-67ee-4016-9012-cc0cdc7b18f2"
export function SecondaryNavB1ad2911256a4c84B99f957e201f9848(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavB1ad2911256a4c84B99f957e201f9848", parentTag)} {...props} />
  );
}

// id: "dda274b0-316b-4f37-b684-490f323eaaa9"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "c0ed84f3-2c3d-42a3-ab25-9d46d24014a0"
export function CopyrightDda274b0316b4f37B684490f323eaaa9(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("CopyrightDda274b0316b4f37B684490f323eaaa9", parentTag)} {...props} />
  );
}

// id: "5ea963f9-755b-4186-ad38-f83a2790890c"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "d57cb82b-e91f-435e-8423-3c6455df47d8"
export function LinkedLogo5ea963f9755b4186Ad38F83a2790890c(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo5ea963f9755b4186Ad38F83a2790890c", parentTag)} label={<DefaultLogoB775c3f720f749fdA09d26419e641cf3 parentTag="LabelFb8fe0577e55429eAfffAf20a576a33a" alt="logo" />} {...props} />
  );
}

// id: "0298f0db-2a3b-44e6-ba85-80c98c13a042"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4ab8c340-9caf-49df-bb2c-7265428fcfe3"
export function Header0298f0db2a3b44e6Ba8580c98c13a042(props: any) {
  return (
    <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header0298f0db2a3b44e6Ba8580c98c13a042" title="Join Us" {...props} />
  );
}

// id: "e835bd2f-bd51-4bae-8cf9-3e568b9bda49"
// title: ""
// type: :text
// key: "title"
// parent_id: "4dfbaa80-4310-4cef-8334-1016490dcbd2"
export const TitleE835bd2fBd514bae8cf93e568b9bda49 = null;

// id: "9e439bae-f0f4-4d6c-91e2-51ee9c66de00"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "d57cb82b-e91f-435e-8423-3c6455df47d8"
export const BackgroundImage9e439baeF0f44d6c91e251ee9c66de00 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "07cd5a03-6ac4-4418-87e4-8a7f469f8dc1"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "3efb211c-7e0f-4c05-baf6-f4c8b745d8da"
export function MarketingCards07cd5a036ac4441887e48a7f469f8dc1(props: any) {
  return (
    <MarketingCards8b7f15f432b847969cceB515537c9386 parentTag="MarketingCards07cd5a036ac4441887e48a7f469f8dc1" postSlug="home-page-posts2" {...props} />
  );
}

// id: "7fdb7bea-54ce-4414-adc3-64552333b339"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "e59e5986-508e-4202-a74a-97f145e038fc"
export function Button7fdb7bea54ce4414Adc364552333b339(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button7fdb7bea54ce4414Adc364552333b339", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "84f8dd58-b98c-413d-a42a-2ab714389c6b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "107df6af-788e-450e-b6f6-355309bdb217"
export function Header84f8dd58B98c413dA42a2ab714389c6b(props: any) {
  return (
    <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header84f8dd58B98c413dA42a2ab714389c6b" title="Public Events" {...props} />
  );
}

// id: "b6cddcd6-7930-4ab3-92bd-7c64b0d2770e"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "da7c4aea-db4e-46ca-8b43-e72d983e8786"
export function FooterB6cddcd679304ab392bd7c64b0d2770e(props: any) {
  return (
    <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="FooterB6cddcd679304ab392bd7c64b0d2770e" {...props} />
  );
}

// id: "4bf0be39-b8a7-4f98-b515-7cecc4cc9c29"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "deb2d979-2771-4849-8fb0-2c7e2e0569ea"
export function Footer4bf0be39B8a74f98B5157cecc4cc9c29(props: any) {
  return (
    <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="Footer4bf0be39B8a74f98B5157cecc4cc9c29" {...props} />
  );
}

// id: "e6453af8-6e36-45ee-af12-a981e97a2f3b"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "ad2fd0f0-ef24-4340-bc43-751a28d7648b"
export function NavigationE6453af86e3645eeAf12A981e97a2f3b(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationE6453af86e3645eeAf12A981e97a2f3b", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "0cd13f50-def5-48c8-92d7-c1c919ef3fc1"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7970c532-ac5b-4fea-96b0-bef37ca962fd"
export function Header0cd13f50Def548c892d7C1c919ef3fc1(props: any) {
  return (
    <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header0cd13f50Def548c892d7C1c919ef3fc1" title="About Us" {...props} />
  );
}

// id: "a024439c-dc10-426f-bcb6-70b6d840220d"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "4815606b-f63e-4702-8896-62262873f889"
export function HeaderTopA024439cDc10426fBcb670b6d840220d(props: any) {
  return (
    <DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b parentTag="HeaderTopA024439cDc10426fBcb670b6d840220d" {...props} />
  );
}

// id: "1f04d66c-4aa5-4457-a4b9-2db32a364122"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "107df6af-788e-450e-b6f6-355309bdb217"
export function Footer1f04d66c4aa54457A4b92db32a364122(props: any) {
  return (
    <DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0 parentTag="Footer1f04d66c4aa54457A4b92db32a364122" {...props} />
  );
}

// id: "94e54ebb-15ba-499d-94f2-2b081ef6ee0e"
// title: ""
// type: :text
// key: "title"
// parent_id: "2edaff9a-9aed-47aa-b9ba-7b20c129f8ef"
export const Title94e54ebb15ba499d94f22b081ef6ee0e = "Default Title";

// id: "767a2d64-1b1d-46d1-a567-ba70828cc5d3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "92e44c81-67cc-4039-a5bc-1e66a0f2be9f"
export function Header767a2d641b1d46d1A567Ba70828cc5d3(props: any) {
  return (
    <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header767a2d641b1d46d1A567Ba70828cc5d3" title="Resources" {...props} />
  );
}

// id: "4c3feff3-0ff8-4303-95e1-edf9d61d639c"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "4ef4c2da-dd41-46d2-a0f0-f5bd72161148"
export const BackgroundImage4c3feff30ff8430395e1Edf9d61d639c = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "7e66b6cc-8ebb-4149-afe1-8e72509888e1"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "d57cb82b-e91f-435e-8423-3c6455df47d8"
export function Navigation7e66b6cc8ebb4149Afe18e72509888e1(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation7e66b6cc8ebb4149Afe18e72509888e1", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "d4f3fbe1-c16f-41dd-880a-97d1480f5c4c"
// title: ""
// type: :html
// key: "logo"
// parent_id: "c0ed84f3-2c3d-42a3-ab25-9d46d24014a0"
export function LogoD4f3fbe1C16f41dd880a97d1480f5c4c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} LogoD4f3fbe1C16f41dd880a97d1480f5c4c logo`} />
  );
}

// id: "de7f07dd-d534-42f8-8551-8ca932c5c5f8"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "eee0c835-5dd3-4fe7-8a0d-182ff7147c6c"
export function HeaderTopDe7f07ddD53442f885518ca932c5c5f8(props: any) {
  return (
    <DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b parentTag="HeaderTopDe7f07ddD53442f885518ca932c5c5f8" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "3e992504-ac19-4fff-acb2-03a6ae7120e4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d042eabb-49fb-4abc-ba34-564de8e63056"
export function Header3e992504Ac194fffAcb203a6ae7120e4(props: any) {
  return (
    <SecondaryMarketingHeader4815606bF63e4702889662262873f889 parentTag="Header3e992504Ac194fffAcb203a6ae7120e4" title="Join Us" {...props} />
  );
}

// id: "916d1aa6-f84b-4c6b-908f-080ab143d980"
// title: ""
// type: :text
// key: "alt"
// parent_id: "b775c3f7-20f7-49fd-a09d-26419e641cf3"
export const Alt916d1aa6F84b4c6b908f080ab143d980 = "logo";

// id: "c795b17d-3a4f-4ace-ac34-23a0b83ebea2"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "c0ed84f3-2c3d-42a3-ab25-9d46d24014a0"
export function GroupflowC795b17d3a4f4aceAc3423a0b83ebea2(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowC795b17d3a4f4aceAc3423a0b83ebea2", parentTag)} {...props} />
  );
}

// id: "c362784d-c7a5-492b-8c2f-ab5a63bb54f8"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "4dfbaa80-4310-4cef-8334-1016490dcbd2"
export const NavMenuSlugC362784dC7a5492b8c2fAb5a63bb54f8 = "marketing-primary-nav";

// id: "6b02f85f-d6fd-416c-a3f0-bccf5e546cd7"
// title: ""
// type: :reference
// key: "body"
// parent_id: "7970c532-ac5b-4fea-96b0-bef37ca962fd"
export function Body6b02f85fD6fd416cA3f0Bccf5e546cd7(props: any) {
  return (
    <MarketingBody3efb211c7e0f4c05Baf6F4c8b745d8da parentTag="Body6b02f85fD6fd416cA3f0Bccf5e546cd7" postSlug="about-us-posts" {...props} />
  );
}

// id: "5fcdfe5d-996c-4b50-ab54-78d5c3995b1a"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "aae363fd-fd2f-466f-ae8f-31e06d6eff6e"
export function Quote5fcdfe5d996c4b50Ab5478d5c3995b1a(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote5fcdfe5d996c4b50Ab5478d5c3995b1a", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "e10d5893-b907-4d2c-b401-9294c52b1db6"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "b775c3f7-20f7-49fd-a09d-26419e641cf3"
export const ImageUrlE10d5893B9074d2cB4019294c52b1db6 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "a9fee9ba-c567-43b0-a98e-9cc2507906df"
// title: ""
// type: :text
// key: "title"
// parent_id: "4815606b-f63e-4702-8896-62262873f889"
export const TitleA9fee9baC56743b0A98e9cc2507906df = "Secondary Marketing Title 1";

// id: "ebbe9be1-4aa4-4bf7-a49a-f6e223ec9fa1"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "4ef4c2da-dd41-46d2-a0f0-f5bd72161148"
export function HeaderTopEbbe9be14aa44bf7A49aF6e223ec9fa1(props: any) {
  return (
    <DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b parentTag="HeaderTopEbbe9be14aa44bf7A49aF6e223ec9fa1" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "e1bae530-eeb3-4333-8f16-8cfbbde83255"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "e3385039-e269-44fe-b373-373ceaeebd75"
export const WidgetIdE1bae530Eeb343338f168cfbbde83255 = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "322a2137-e235-4242-8f0f-cd4f6fe8cff4"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "ebbe9be1-4aa4-4bf7-a49a-f6e223ec9fa1"
export const WidgetId322a2137E23542428f0fCd4f6fe8cff4 = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "2f8e7724-83a4-4614-a772-5d1a0b87cb79"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "de7f07dd-d534-42f8-8551-8ca932c5c5f8"
export const NavMenuSlug2f8e772483a44614A7725d1a0b87cb79 = "members-primary-nav";

// id: "c0fb43f3-6c84-4c20-9d42-7441cb70043b"
// title: ""
// type: :text
// key: "href"
// parent_id: "7fdb7bea-54ce-4414-adc3-64552333b339"
export const HrefC0fb43f36c844c209d427441cb70043b = "/join";

// id: "f89f20e1-538d-4922-ae12-c0a47c9089a8"
// title: ""
// type: :text
// key: "title"
// parent_id: "84f8dd58-b98c-413d-a42a-2ab714389c6b"
export const TitleF89f20e1538d4922Ae12C0a47c9089a8 = "Public Events";

// id: "f802d4f7-7cc9-460c-90b4-b5269fcfcb05"
// title: ""
// type: :text
// key: "label"
// parent_id: "7fdb7bea-54ce-4414-adc3-64552333b339"
export const LabelF802d4f77cc9460c90b4B5269fcfcb05 = "Join Us";

// id: "3e4f916b-6a80-42b4-983d-3841be2c0377"
// title: ""
// type: :text
// key: "url"
// parent_id: "bbc857f7-73ee-4997-b12e-c6e06cb8d884"
export const Url3e4f916b6a8042b4983d3841be2c0377 = "/";

// id: "a8a5f939-a9eb-4a5f-b840-0a23c5acdd8e"
// title: ""
// type: :text
// key: "class"
// parent_id: "e6453af8-6e36-45ee-af12-a981e97a2f3b"
export const ClassA8a5f939A9eb4a5fB8400a23c5acdd8e = "navigation";

// id: "a6153fad-95b3-4df3-85c2-2102eb05ea3f"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "5fcdfe5d-996c-4b50-ab54-78d5c3995b1a"
export const ContentSlugA6153fad95b34df385c22102eb05ea3f = "home-page-quote";

// id: "0b7fd3ef-74c0-4caf-a00e-7a447d003302"
// title: ""
// type: :text
// key: "title"
// parent_id: "c61fc4ee-a5f7-4dd3-8947-2070dc1ac8af"
export const Title0b7fd3ef74c04cafA00e7a447d003302 = "Secondary Page";

// id: "60b2ee0c-2801-4af5-b10d-ee02f3822e5c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "849e2304-881f-49d8-a0ef-f0c5c96ac99c"
export const NavMenuSlug60b2ee0c28014af5B10dEe02f3822e5c = "footer-nav";

// id: "a5bb1166-94ce-48e2-9117-5691ffd7a06b"
// title: ""
// type: :text
// key: "class"
// parent_id: "bbc857f7-73ee-4997-b12e-c6e06cb8d884"
export const ClassA5bb116694ce48e291175691ffd7a06b = "linked-logo";

// id: "165a2b19-ca61-4146-aaa7-0a2c31647343"
// title: ""
// type: :text
// key: "title"
// parent_id: "58d7e8d6-0a95-435e-af2a-f8520b0f9aab"
export const Title165a2b19Ca614146Aaa70a2c31647343 = "Secondary Marketing Title 2";

// id: "e66842cf-d212-4ca3-9f66-7de50e4e9061"
// title: ""
// type: :text
// key: "title"
// parent_id: "0cd13f50-def5-48c8-92d7-c1c919ef3fc1"
export const TitleE66842cfD2124ca39f667de50e4e9061 = "About Us";

// id: "260f36df-3495-4b8d-88d7-a8f4b4c40dfb"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "1430fcb0-5685-4256-9d6a-7891c4d52b09"
export const NavMenuSlug260f36df34954b8d88d7A8f4b4c40dfb = "members-primary-nav";

// id: "cb792dc9-c2a1-48c4-bf08-6cb5a438eff3"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "e6453af8-6e36-45ee-af12-a981e97a2f3b"
export const NavMenuSlugCb792dc9C2a148c4Bf086cb5a438eff3 = "marketing-primary-nav";

// id: "4e40c6ab-e651-40ba-ad0b-735b1bd4e7eb"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "07cd5a03-6ac4-4418-87e4-8a7f469f8dc1"
export const PostSlug4e40c6abE65140baAd0b735b1bd4e7eb = "home-page-posts2";

// id: "fb8fe057-7e55-429e-afff-af20a576a33a"
// title: ""
// type: :reference
// key: "label"
// parent_id: "5ea963f9-755b-4186-ad38-f83a2790890c"
export function LabelFb8fe0577e55429eAfffAf20a576a33a(props: any) {
  return (
    <DefaultLogoB775c3f720f749fdA09d26419e641cf3 parentTag="LabelFb8fe0577e55429eAfffAf20a576a33a" {...props} />
  );
}

// id: "5d62fd17-45ce-4cca-94a2-b60a64f63b70"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "6b02f85f-d6fd-416c-a3f0-bccf5e546cd7"
export const PostSlug5d62fd1745ce4cca94a2B60a64f63b70 = "about-us-posts";

// id: "8721d187-28d7-42b5-b33c-10a1e26112ca"
// title: ""
// type: :text
// key: "title"
// parent_id: "3e992504-ac19-4fff-acb2-03a6ae7120e4"
export const Title8721d18728d742b5B33c10a1e26112ca = "Join Us";

// id: "2a9e57e6-cd6e-4ef1-9245-294f9293118b"
// title: ""
// type: :text
// key: "title"
// parent_id: "2181c56b-b8eb-4da3-83df-3ee38f4d127e"
export const Title2a9e57e6Cd6e4ef19245294f9293118b = "Leveling";

// id: "da92a578-7d73-43e1-85f3-f49fb30a5482"
// title: ""
// type: :text
// key: "title"
// parent_id: "767a2d64-1b1d-46d1-a567-ba70828cc5d3"
export const TitleDa92a5787d7343e185f3F49fb30a5482 = "Resources";

// id: "4f4d4e2f-dffc-4df7-b65c-31a532ee0492"
// title: ""
// type: :reference
// key: "label"
// parent_id: "bbc857f7-73ee-4997-b12e-c6e06cb8d884"
export function Label4f4d4e2fDffc4df7B65c31a532ee0492(props: any) {
  return (
    <DefaultLogoB775c3f720f749fdA09d26419e641cf3 parentTag="Label4f4d4e2fDffc4df7B65c31a532ee0492" {...props} />
  );
}

// id: "8bb84cde-5730-446c-b39a-ec47427a1c19"
// title: ""
// type: :text
// key: "title"
// parent_id: "0298f0db-2a3b-44e6-ba85-80c98c13a042"
export const Title8bb84cde5730446cB39aEc47427a1c19 = "Join Us";

// id: "18f03999-4a84-4d68-9247-3b560bbb5dba"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "7e66b6cc-8ebb-4149-afe1-8e72509888e1"
export const NavMenuSlug18f039994a844d6892473b560bbb5dba = "members-primary-nav";

const Components = {
  MarketingCards8b7f15f432b847969cceB515537c9386,
  Title4f725fd32a64406881b798f34c4d05de,
  AdminPages552690969e3f487dB01b49ad4f701d96,
  DefaultHeader4dfbaa8043104cef83341016490dcbd2,
  MembersAreaHeaderEee0c8355dd34fe78a0d182ff7147c6c,
  MarketingBody3efb211c7e0f4c05Baf6F4c8b745d8da,
  AboutUsPage7970c532Ac5b4fea96b0Bef37ca962fd,
  PublicEventsPage107df6af788e450eB6f6355309bdb217,
  MemberPages377555a967ee40169012Cc0cdc7b18f2,
  MembersAreaDefaultB4bbfb01B716404a92bdEad16728f20f,
  SecondaryPageDa7c4aeaDb4e46ca8b43E72d983e8786,
  ResourcesPage92e44c8167cc4039A5bc1e66a0f2be9f,
  LabelAdae0295A4664693Bff34a04dd43b732,
  ShortHeaderD57cb82bE91f435e84233c6455df47d8,
  DefaultLogoB775c3f720f749fdA09d26419e641cf3,
  PageQuoteFc164688Dcbc4606803b085e7575d74c,
  JoinUsPageD042eabb49fb4abcBa34564de8e63056,
  Href17195ad0Eb36487c8c4456d7269b8d61,
  FooterD23f2e9aB24644dcAd3a0bd22ffc8ea8,
  SignupPages4ab8c3409caf49dfBb2c7265428fcfe3,
  SecondaryMarketingHero2edaff9a9aed47aaB9ba7b20c129f8ef,
  Header5e0b88fcBa134013AddfF96b9db0dcbe,
  DefaultFooterC0ed84f32c3d42a3Ab259d46d24014a0,
  DefaultHeaderTopAd2fd0f0Ef244340Bc43751a28d7648b,
  AboutLevelingPageDeb2d979277148498fb02c7e2e0569ea,
  MessagePages89518373Bc404effAf6d2d4718461a7d,
  HomePageHeroE59e5986508e4202A74a97f145e038fc,
  SecondaryMarketingHeader4815606bF63e4702889662262873f889,
  HomePageAae363fdFd2f466fAe8f31e06d6eff6e,
  HomeHeader4ef4c2daDd4146d2A0f0F5bd72161148,
  Links849e2304881f49d8A0efF0c5c96ac99c,
  Hero58d7e8d60a95435eAf2aF8520b0f9aab,
  Footer65b9f93a48fb42ea879328caaca094a5,
  Header1430fcb0568542569d6a7891c4d52b09,
  HeaderFac9f4a08e774048Bda04105f3eeba98,
  Header24841a2074594180B2489a61daf1a042,
  WelcomeBack56c1117cBdc64c36B970Dec6edbf88d3,
  FooterEa327cd6F9504e9a9ba9Cfcd43975931,
  FooterF075f216Aa5e43f8A05804e8f37f4186,
  Footer1b54ae1104df4e898676A0b399e67cc5,
  HeaderC61fc4eeA5f74dd389472070dc1ac8af,
  PostSlugCbdf4c424d584888950732f880482563,
  Header5e8b964d34364f4dB208Eb634d0a86f3,
  LinkedLogoBbc857f773ee4997B12eC6e06cb8d884,
  Body3c2dc225Beaa43ca9d6109ef49ccf6da,
  Header2181c56bB8eb4da383df3ee38f4d127e,
  HeroE3385039E26944feB373373ceaeebd75,
  ButtonClassE7a6b89094eb44a1992604ca79e36fde,
  SecondaryNavB1ad2911256a4c84B99f957e201f9848,
  CopyrightDda274b0316b4f37B684490f323eaaa9,
  LinkedLogo5ea963f9755b4186Ad38F83a2790890c,
  Header0298f0db2a3b44e6Ba8580c98c13a042,
  TitleE835bd2fBd514bae8cf93e568b9bda49,
  BackgroundImage9e439baeF0f44d6c91e251ee9c66de00,
  MarketingCards07cd5a036ac4441887e48a7f469f8dc1,
  Button7fdb7bea54ce4414Adc364552333b339,
  Header84f8dd58B98c413dA42a2ab714389c6b,
  FooterB6cddcd679304ab392bd7c64b0d2770e,
  Footer4bf0be39B8a74f98B5157cecc4cc9c29,
  NavigationE6453af86e3645eeAf12A981e97a2f3b,
  Header0cd13f50Def548c892d7C1c919ef3fc1,
  HeaderTopA024439cDc10426fBcb670b6d840220d,
  Footer1f04d66c4aa54457A4b92db32a364122,
  Title94e54ebb15ba499d94f22b081ef6ee0e,
  Header767a2d641b1d46d1A567Ba70828cc5d3,
  BackgroundImage4c3feff30ff8430395e1Edf9d61d639c,
  Navigation7e66b6cc8ebb4149Afe18e72509888e1,
  LogoD4f3fbe1C16f41dd880a97d1480f5c4c,
  HeaderTopDe7f07ddD53442f885518ca932c5c5f8,
  Header3e992504Ac194fffAcb203a6ae7120e4,
  Alt916d1aa6F84b4c6b908f080ab143d980,
  GroupflowC795b17d3a4f4aceAc3423a0b83ebea2,
  NavMenuSlugC362784dC7a5492b8c2fAb5a63bb54f8,
  Body6b02f85fD6fd416cA3f0Bccf5e546cd7,
  Quote5fcdfe5d996c4b50Ab5478d5c3995b1a,
  ImageUrlE10d5893B9074d2cB4019294c52b1db6,
  TitleA9fee9baC56743b0A98e9cc2507906df,
  HeaderTopEbbe9be14aa44bf7A49aF6e223ec9fa1,
  WidgetIdE1bae530Eeb343338f168cfbbde83255,
  WidgetId322a2137E23542428f0fCd4f6fe8cff4,
  NavMenuSlug2f8e772483a44614A7725d1a0b87cb79,
  HrefC0fb43f36c844c209d427441cb70043b,
  TitleF89f20e1538d4922Ae12C0a47c9089a8,
  LabelF802d4f77cc9460c90b4B5269fcfcb05,
  Url3e4f916b6a8042b4983d3841be2c0377,
  ClassA8a5f939A9eb4a5fB8400a23c5acdd8e,
  ContentSlugA6153fad95b34df385c22102eb05ea3f,
  Title0b7fd3ef74c04cafA00e7a447d003302,
  NavMenuSlug60b2ee0c28014af5B10dEe02f3822e5c,
  ClassA5bb116694ce48e291175691ffd7a06b,
  Title165a2b19Ca614146Aaa70a2c31647343,
  TitleE66842cfD2124ca39f667de50e4e9061,
  NavMenuSlug260f36df34954b8d88d7A8f4b4c40dfb,
  NavMenuSlugCb792dc9C2a148c4Bf086cb5a438eff3,
  PostSlug4e40c6abE65140baAd0b735b1bd4e7eb,
  LabelFb8fe0577e55429eAfffAf20a576a33a,
  PostSlug5d62fd1745ce4cca94a2B60a64f63b70,
  Title8721d18728d742b5B33c10a1e26112ca,
  Title2a9e57e6Cd6e4ef19245294f9293118b,
  TitleDa92a5787d7343e185f3F49fb30a5482,
  Label4f4d4e2fDffc4df7B65c31a532ee0492,
  Title8bb84cde5730446cB39aEc47427a1c19,
  NavMenuSlug18f039994a844d6892473b560bbb5dba
}

export default Components;