const ROUTES = {
  "/": {
    "name": "HomePageAae363fdFd2f466fAe8f31e06d6eff6e",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages552690969e3f487dB01b49ad4f701d96",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage7970c532Ac5b4fea96b0Bef37ca962fd",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageDeb2d979277148498fb02c7e2e0569ea",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage107df6af788e450eB6f6355309bdb217",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageD042eabb49fb4abcBa34564de8e63056",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages377555a967ee40169012Cc0cdc7b18f2",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages89518373Bc404effAf6d2d4718461a7d",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage92e44c8167cc4039A5bc1e66a0f2be9f",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages4ab8c3409caf49dfBb2c7265428fcfe3",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;